/**
 * Blog layout for markdown
 *
 * @author Anja Bisako <anja.bisako@thingos.io>
 * @version 0.0.1
 */
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { IndexLayout } from '../layouts';
import lightingColors from '../styles/lighting';
import { Contact } from './Contact';
import { Container } from './Container';
import { MeshlightingFooter } from './MeshlightingFooter';
import { Page } from './Page';
import { HeaderH2, Subtitle } from './Typography';

import seperatorIndicator from '../svgs/seperator-indicator.svg';

const BlogPostStyle = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginTop: '2rem',
	marginBottom: '2rem',
	color: '#fff',
	'>*': {
		color: '#fff',
	},
});

const BlogFeaturedImg = styled.div({
	display: 'flex',
	flex: 1,
	height: '25rem',
	'@media screen and (max-width: 600px)': {
		height: '15rem',
	},
});

const PostCategoryStyleMainPost = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	alignItems: 'baseline',
	marginBottom: '1.5rem',
	marginTop: '3rem',
	'@media screen and (max-width:600px)': {
		flexDirection: 'column',
		'>*': { marginTop: '0.3rem' },
	},
});

function formatDate(dateString: string) {
	const postDate = new Date(dateString);
	const dateLabel = `${postDate.getDate().toString().padStart(2, '0')}.${(postDate.getMonth() + 1)
		.toString()
		.padStart(2, '0')}.${postDate.getFullYear()}`;
	return dateLabel;
}

export const getCategoryColor = (category: string) => {
	if (category == 'Meshthings App') return lightingColors.highlight.orange;
	else if (category == 'Meshthing Development') return lightingColors.highlight.pink;
	else if (category == 'Bluetooth® Mesh') return lightingColors.highlight.blue;
	else if (category == 'Matter') return lightingColors.highlight.mint;
	else return '#888';
};

export const BlogLayout: React.FC<PageProps> = props => {
	const { t } = useTranslation('developers');
	const posts = useStaticQuery(query);

	const children = props.children;

	const post = (
		props.pageContext as unknown as {
			frontmatter: {
				path: string;
				category: string;
				date: string;
				title: string;
				description: string;
				featuredImagePath: string;
			};
		}
	).frontmatter;

	const dateLabel = formatDate(post.date);

	let postImage = null;
	posts.allFile.edges.map((image: any) => {
		if (image.node.base === post.featuredImagePath) {
			postImage = getImage(image.node);
		}
	});

	return (
		<IndexLayout tabName={post.title}>
			<Page sectionBackground="darkBackground" padding="0">
				<BlogFeaturedImg>
					{postImage && <GatsbyImage image={postImage} style={{ flex: 1 }} alt="Title image" />}
				</BlogFeaturedImg>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container style={{ color: '#fff' }}>
					<PostCategoryStyleMainPost>
						<div
							style={{
								paddingLeft: '10px',
								paddingRight: '10px',
								borderRadius: '16px',
								backgroundColor: getCategoryColor(post.category),
								marginRight: '10px',
							}}
						>
							<strong
								style={{
									marginTop: 0,
									color: lightingColors.white,
								}}
							>
								{post.category}
							</strong>
						</div>
						<div>– {dateLabel}</div>
					</PostCategoryStyleMainPost>
					<HeaderH2>{post.title}</HeaderH2>
					<img src={seperatorIndicator} alt="separator" style={{ marginBottom: '2rem' }} />
					<Subtitle>{post.description}</Subtitle>

					<BlogPostStyle>{children}</BlogPostStyle>

					<div style={{ textAlign: 'center' }}>
						<img src={seperatorIndicator} alt="separator" style={{ marginBottom: '2rem' }} />
					</div>
				</Container>
			</Page>

			{/* read more */}
			{/* <Page sectionBackground="lightBackground">
				<Container style={{ color: '#fff' }}>
					<HeaderH3 marginBottom="1rem">Read more</HeaderH3>
					<img src={seperatorIndicator} alt="separator" style={{ marginBottom: '2rem' }}></img>

					<PostRowStyle>
						{posts.allMdx.edges.map((edge: any, index: number) => {
							let postInfo: {
								path: any;
								category: string;
								date: Date;
								title: string;
								description: string;
								featuredImagePath: string;
							} = edge.node.frontmatter;

							// not current
							if (postInfo.path !== post.path) {
								let imgPath = postInfo.featuredImagePath;
								let featuredImg: any = null;
								posts.allFile.edges.map((image: any) => {
									if (image.node.base === imgPath) {
										featuredImg = image.node.childImageSharp.fluid;
									}
								});

								// each post must contain a featured image
								if (featuredImg != null) {
									countToTwoReadMorePosts += 1;
									if (countToTwoReadMorePosts <= 2) {
										return (
											<PostColumnStyle key={index}>
												<MenuLink to={postInfo.path}>
													<StyledImage>
														<Img
															fluid={featuredImg}
															imgStyle={{ borderRadius: '8px 8px 0px 0px' }}
														/>
													</StyledImage>
												</MenuLink>
												<InnerPaddingStyle>
													<PostCategoryStyle>
														<div
															style={{
																paddingLeft: '10px',
																paddingRight: '10px',
																borderRadius: '16px',
																backgroundColor: getCategoryColor(postInfo.category)
															}}
														>
															<strong
																style={{
																	marginTop: 0,
																	color: lightingColors.white,
																}}
															>
																{postInfo.category}
															</strong>
														</div>
														<div>{postInfo.date}</div>
													</PostCategoryStyle>
													<MenuLink to={postInfo.path}>
														<HeaderH3 marginBottom="0.5rem" style={{ marginTop: '0.5rem' }}>
															{postInfo.title}
														</HeaderH3>
													</MenuLink>
													<WideBody>{postInfo.description}</WideBody>
												</InnerPaddingStyle>
											</PostColumnStyle>
										);
									}
								}
							}
						})}
					</PostRowStyle>
				</Container>
			</Page> */}

			<Page sectionBackground="darkBackground" id="Contact">
				<Container>
					<Contact
						title={t('contact.title')}
						text={t('contact.description')}
						buttonText={t('contact.button')}
						url={'mailto:info@thingos.io'}
					/>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<MeshlightingFooter />
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query {
		allMdx(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { published: { eq: true } } }
		) {
			edges {
				node {
					frontmatter {
						path
						category
						date
						title
						description
						featuredImagePath
					}
				}
			}
		}
		allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "blog-posts" }
			}
		) {
			edges {
				node {
					base
					childImageSharp {
						gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
					}
				}
			}
		}
	}
`;

// we need to export as default for mdx plugin
export default BlogLayout;
