/**
 * Footer
 *
 * @author Anja Bisako
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { HStack } from '@domir/react-stacks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { Footer, Text } from '@thingos/thingos-homepage-shared';

import { MenuLink } from './MenuLink';

// import { Body, Footnote, LocalLink } from './Typography';

export const MeshlightingFooter: React.FC = () => {
	const { t } = useTranslation('translation');

	return (
		<Footer.Container>
			<HStack margin="2rem">
				<Footer.SocialLink
					url="https://www.linkedin.com/showcase/meshlighting"
					icon="services/linkedIn"
				/>
				<Footer.SocialLink url="https://twitter.com/thingosio" icon="services/twitter" />
				<Footer.SocialLink url="https://www.facebook.com/meshlighting" icon="services/facebook" />
				<Footer.SocialLink
					url="https://www.youtube.com/channel/UC707pLH93SPJVbyagcKqfPg"
					icon="services/youtube"
				/>
			</HStack>
			<Text.Body style={{ marginBottom: '2rem', color: 'rgba(255,255,255,0.34)' }}>
				{t('footer.bluetooth')}
			</Text.Body>
			<Text.Body style={{ marginBottom: '1rem' }}>
				<strong>
					<Text.Link href="https://www.thingos.io" target="_blank">
						{t('footer.property')}
					</Text.Link>
				</strong>
			</Text.Body>
			<Text.Body style={{ marginBottom: '4rem' }}>
				{t('footer.made')}&nbsp;&nbsp;
				<span style={{ color: 'red', height: '10px' }}>&#10084;&#65039;</span>
				&nbsp;
				{t('footer.location')}
			</Text.Body>
			<HStack spacing="2rem" justifyContent="center">
				<Text.Footnote>© ThingOS GmbH & Co. KG 2022</Text.Footnote>
				<Text.Footnote>
					<MenuLink to="/mesh-imprint">{t('footer.imprint')}</MenuLink>
				</Text.Footnote>
				<Text.Footnote>
					<MenuLink to="/mesh-privacy">{t('footer.privacy')}</MenuLink>
				</Text.Footnote>
			</HStack>
		</Footer.Container>
	);
};
