import * as React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button({
	border: '2px solid #fff',
	borderRadius: 8,
	padding: 10,
	width: 150,
	color: '#fff',
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: 'rgb(255,255,255,0)',
	transition: '0.3s',

	'&:hover': {
		backgroundColor: 'rgba(255,255,255,1)',
		color: '#171635',
		transition: '0.3s',
		boxShadow: 'rgba(255, 255, 255, 0.5) 0px 0px 10px',
	},
});

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
	background?: boolean;
}

export const Button: React.FC<ButtonProps> = props => {
	const { children, background, ...restProps } = props;
	return (
		<StyledButton
			style={background === true ? { backgroundColor: 'rgba(255,255,255,0.1)' } : {}}
			{...(restProps as any)}
		>
			{children}
		</StyledButton>
	);
};
