import * as React from 'react';
import styled from 'styled-components';

import lightingColors from '../styles/lighting';
import { Button } from './Button';
import { HeaderH3, WideBody } from './Typography';

const ContactLayout = styled.div`
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-top: 8rem;
	margin-bottom: 8rem;
	padding: 2rem 1rem;
	background: linear-gradient(
		to bottom right,
		${lightingColors.highlight.pink},
		${lightingColors.highlight.orange}
	);
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	transition: 0.5s;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
		transition: 0.5s;
	}

	& > :not(:last-child) {
		margin-bottom: 1rem;
		margin-top: 0;
	}
`;

interface ContactProps {
	title: string;
	text: string;
	buttonText: string;
	url: string;
}

export const Contact: React.FC<ContactProps> = props => {
	const { title, text, buttonText, url } = props;

	return (
		<ContactLayout>
			<HeaderH3>{title}</HeaderH3>
			<WideBody>{text}</WideBody>
			<a href={url} style={{ textDecoration: 'none' }}>
				<Button>{buttonText}</Button>
			</a>
		</ContactLayout>
	);
};
