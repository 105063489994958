import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

import lightingColors from '../styles/lighting';

export const MenuLink = styled(Link)({
	color: lightingColors.highlight.orange,
	textDecoration: 'none !important',
	'&:hover': {
		textDecoration: 'none',
	},
});
