import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/cloud/thingos-homepage/packages/meshlighting/src/components/BlogLayout.tsx";
import { graphql } from 'gatsby';
import { Container } from '../../components/Container';
import { VideoContainer } from '../../components/VideoContainer';
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		alexa: file(relativePath: { eq: "blog-posts/scene-arena-day" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
	}
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Container style={{
      width: '100%',
      padding: 0
    }} mdxType="Container">
	<VideoContainer source="https://www.youtube.com/embed/IyonVPVtmAI?modestbranding=1&color=white" mdxType="VideoContainer" />
    </Container>
    <p>{`Zur Evaluation unserer Meshlighing Firmware und den Möglichkeiten, die uns die Bluetooth® Mesh Technologie bietet, haben wir 70 modulare Mesh-Geräte auf der gesamten Länge der ARENA2036 (ca. 100m) platziert. Das daraus entstandene Bluetooth® Mesh Netzwerk deckt die gesamte Fläche der ARENA ab und bietet Knotenpunkte, zu denen sich andere Bluetooth® Mesh fähige Geräte verbinden können. Die verwendeten schlichten Gehäuse bieten Platz für jeweils einen Akku und ein Meshthing samt LED-Modul. Mithilfe unserer Meshthings App, konnten die Mesh-Geräte provisioniert, eingerichtet und in einer gemeinsamen Gruppe zusammengefasst werden. Wie genau die unkomplizierte und schnelle Einrichtung unserer Test-Module funktioniert, können sie in unserem "Getting Started" Video ansehen.`}</p>
    <p>{`Das Ergebnis unseres Tests zeigt wie zuverlässig und stabil die Meshthings und die darauf installierte Meshlighting Firmware laufen. Bei Fragen zu unseren Produkten, freuen wir uns über Ihre Nachricht!`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      